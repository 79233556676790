var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"10","lg":"11"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"1"}},[_c('v-select',{staticClass:"pageLanguageSelector pr-3",attrs:{"items":_vm.pageLanguages,"single-line":""},model:{value:(_vm.pageDefaultLanguage),callback:function ($$v) {_vm.pageDefaultLanguage=$$v},expression:"pageDefaultLanguage"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"11"}},_vm._l((_vm.page.Name),function(translatedValue,translatedValueIndex){return _c('div',{key:translatedValueIndex},[(_vm.pageDefaultLanguage == translatedValueIndex)?_c('v-text-field',{attrs:{"label":_vm.$t('Name'),"single-line":"","required":"","rules":[
                (v) => !!v || _vm.$t('Name is required'),
                (v) =>
                  v.length >= 2 ||
                  _vm.$t('Minimum required characters is') + ' 2',
              ],"validate-on-blur":""},model:{value:(_vm.page.Name[translatedValueIndex]),callback:function ($$v) {_vm.$set(_vm.page.Name, translatedValueIndex, $$v)},expression:"page.Name[translatedValueIndex]"}}):_vm._e()],1)}),0)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"2","lg":"1"}},[_c('v-layout',{staticClass:"mt-1",attrs:{"align-center":"","justify-end":"","fill-height":""}},[_c('v-btn',{attrs:{"disabled":_vm.page._id.$oid == 0,"icon":"","color":"primary"},on:{"click":function($event){return _vm.copyItem()}}},[_c('v-icon',[_vm._v("mdi-plus-box-multiple")])],1),_c(_vm.page._id.$oid == 0 ? 'span' : 'router-link',{tag:"router-link",attrs:{"to":{
            name: 'editPageDetails',
            params: {
              name: _vm.getPageName(_vm.page.Name[_vm.currentLanguage]),
              websiteId: _vm.websiteId,
              id: _vm.page._id.$oid,
            },
          }}},[_c('v-btn',{attrs:{"disabled":_vm.page._id.$oid == 0,"icon":"","color":"orange"}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1),_c('v-btn',{attrs:{"disabled":_vm.page._id.$oid == 0,"icon":"","color":"red"},on:{"click":function($event){return _vm.removePage()}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
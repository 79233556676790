<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12" sm="10" lg="11">
        <v-row no-gutters>
          <v-col cols="12" sm="1">
            <v-select
              :items="pageLanguages"
              v-model="pageDefaultLanguage"
              single-line
              class="pageLanguageSelector pr-3"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="11">
            <div
              v-for="(translatedValue, translatedValueIndex) in page.Name"
              :key="translatedValueIndex"
            >
              <v-text-field
                v-if="pageDefaultLanguage == translatedValueIndex"
                :label="$t('Name')"
                v-model="page.Name[translatedValueIndex]"
                single-line
                required
                :rules="[
                  (v) => !!v || $t('Name is required'),
                  (v) =>
                    v.length >= 2 ||
                    $t('Minimum required characters is') + ' 2',
                ]"
                validate-on-blur
              ></v-text-field>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="2" lg="1">
        <v-layout align-center justify-end fill-height class="mt-1">
          <v-btn
            :disabled="page._id.$oid == 0"
            icon
            color="primary"
            v-on:click="copyItem()"
          >
            <v-icon>mdi-plus-box-multiple</v-icon>
          </v-btn>
          <router-link
            :is="page._id.$oid == 0 ? 'span' : 'router-link'"
            :to="{
              name: 'editPageDetails',
              params: {
                name: getPageName(page.Name[currentLanguage]),
                websiteId: websiteId,
                id: page._id.$oid,
              },
            }"
          >
            <v-btn :disabled="page._id.$oid == 0" icon color="orange">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </router-link>
          <v-btn
            :disabled="page._id.$oid == 0"
            icon
            color="red"
            v-on:click="removePage()"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-layout>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: ["page", "websiteId"],
  data() {
    return {
      pageLanguages: null,
      pageDefaultLanguage: null,
    };
  },
  computed: {
    currentLanguage: {
      get: function () {
        return this.$i18n.locale();
      },
    },
  },
  created() {
    this.pageLanguages = Object.keys(this.page.Name);
    this.pageDefaultLanguage = this.pageLanguages[0];
  },
  methods: {
    getPageName(name) {
      const words = name.split(" ");
      return words.join("");
    },
    removePage() {
      this.$emit("removePage");
    },
    copyItem() {
      this.$emit("addWebsiteCopy", this.page._id.$oid);
    },
  },
};
</script>
